<template>
  <section class="pdf-content" v-if="report != null">
    <!-- Header -->
    <section class="content-header pdf-item">
      <img
        src="../../../../assets/images/kenanga_logo.png"
        alt="logo"
        crossorigin="*"
      />
      <h1>SUMMARY TRADING REPORT</h1>
    </section>

    <!-- General Info -->
    <section class="general-information pdf-item">
      <div class="user-info">
        <img
          :src="userProfileImage"
          alt="user avatar"
          crossorigin="Anonymous"
        />
        <div class="user-details">
          <p><b>Display Name</b>: {{ userProfile.display_name }}</p>
          <p><b>Date and Time of Game</b>: {{ formattedGameDateTime }}</p>
          <p><b>Game Type</b>: {{ report.gameSession.game.type }}</p>
          <p><b>Game Name</b>: {{ report.gameSession.game.name }}</p>
        </div>
      </div>
      <div class="game-info">
        <p>
          <b>Return On Investment (%)</b>:
          <span :class="isNegative(report.gameSession.roi) ? 'red' : 'green'"
            >{{ report.gameSession.roi }}%</span
          >
        </p>
        <p>
          <b>Weekly Ranking</b>: {{ report.gameSession.weekly_ranking || 0 }}
        </p>
        <p>
          <b>Monthly Ranking</b>: {{ report.gameSession.monthly_ranking || 0 }}
        </p>
        <p>
          <b>Yearly Ranking</b>: {{ report.gameSession.yearly_ranking || 0 }}
        </p>
      </div>
    </section>

    <!-- Performance Info -->
    <section class="performance-info pdf-item">
      <div>
        <h3>FUNDS MOVEMENT</h3>
        <table cellspacing="0">
          <tbody>
            <tr>
              <td><b>Starting Virtual Capital</b></td>
              <td align="center">
                <b
                  >{{ report.gameSession.game.currency
                  }}{{ report.gameSession.initial_cash | moneyFormat }}</b
                >
              </td>
            </tr>
            <tr>
              <td><b>Accumulated Profit & Loss</b></td>
              <td align="center">
                <b
                  :class="
                    isNegative(report.gameSession.profit) ? 'red' : 'green'
                  "
                  >{{ isNegative(report.gameSession.profit) ? "-" : ""
                  }}{{ report.gameSession.game.currency
                  }}{{ Math.abs(report.gameSession.profit) | moneyFormat }}</b
                >
              </td>
            </tr>
            <tr>
              <td><b>Final Virtual Capital</b></td>
              <td align="center">
                <b
                  >{{ isNegative(report.gameSession.ending_cash) ? "-" : ""
                  }}{{ report.gameSession.game.currency
                  }}{{
                    Math.abs(report.gameSession.ending_cash) | moneyFormat
                  }}</b
                >
              </td>
            </tr>
            <tr>
              <td><b>Number of Margin Call Incurred</b></td>
              <td align="center">
                <b>{{ report.gameSession.margin_count }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="summary-trades">
        <h3>SUMMARY OF TRADES</h3>
        <table cellspacing="0">
          <tbody>
            <tr>
              <td colspan="2" align="center">
                <p><b>Total Trades Done</b></p>
                <h2>{{ report.summary.totalTrades }}</h2>
              </td>
            </tr>
            <tr>
              <td align="center" style="padding:0">
                <p><b>Total Net Buy (contracts)</b></p>
                <h2 class="green">{{ report.summary.totalBuyOrders }}</h2>
              </td>
              <td align="center" style="padding:0">
                <p><b>Total Net Sell (contracts)</b></p>
                <h2 class="red">{{ report.summary.totalSellOrders }}</h2>
              </td>
            </tr>
            <tr>
              <td align="center">
                <p><b>Total Average Buy Price</b></p>
                <h2>
                  {{ formattedValue(report.summary.totalAverageBuyPrice) }}
                </h2>
              </td>
              <td align="center">
                <p><b>Total Average Sell Price</b></p>
                <h2>
                  {{ formattedValue(report.summary.totalAverageSellPrice) }}
                </h2>
              </td>
            </tr>
            <tr>
              <td align="center">
                <p><b>Outstanding Positions</b></p>
                <h2
                  :class="
                    report.summary.outstandingPositions >= 0 ? 'green' : 'red'
                  "
                >
                  {{ report.summary.outstandingPositions }}
                </h2>
              </td>
              <td align="center">
                <p><b>Last Trading Day Price</b></p>
                <h2>
                  {{ formattedValue(report.summary.lastTradingDayPrice) }}
                </h2>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <!-- Order Journal -->
    <section class="order-journal pdf-item">
      <h3>ORDER JOURNAL</h3>
      <div class="grid">
        <table
          cellspacing="0"
          v-for="count in countTotalOrderJournal"
          :key="count"
        >
          <thead>
            <tr>
              <td align="center">Day</td>
              <td align="center">Order</td>
              <td align="center">Quantity</td>
              <td align="center">Price</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in returnCurrentColumnRange(count)"
              :key="index"
            >
              <td>{{ order.day }}</td>
              <td
                :class="
                  order.order == 'BUY'
                    ? 'isBuy'
                    : order.order == 'SELL'
                    ? 'isSell'
                    : ''
                "
              >
                {{ order.order }}
              </td>
              <td>{{ order.quantity }}</td>
              <td>{{ order.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <div class="html2pdf__page-break" />

    <section class="graph-section pdf-item">
      <!-- Net Position -->
      <BarChart
        :chartData="report.gameSessionDays"
        title="Net Position"
        attribute="net_position"
        :ticks="10"
      ></BarChart>

      <!-- P&L Graph -->
      <div class="line-chart">
        <LineChart
          :chartData="report.gameSessionDays"
          title="Profit And Loss"
        ></LineChart>
        <div class="additional-legend">
          <div class="circle"></div>
          <p>Profit</p>
        </div>
      </div>

      <!-- Net Equity Graph -->
      <BarChart
        :chartData="report.gameSessionDays"
        title="Net Equity"
        attribute="net_equity"
        :ticks="10"
      ></BarChart>

      <!-- Equity / Margin Graph -->
      <AreaChart
        :chartData="report.gameSessionDays"
        title="Equity / Margin (%)"
        attribute="equity_margin_ratio"
      ></AreaChart>
    </section>
  </section>
</template>

<script>
import moment from "moment";
import TableColumn from "../../../../components/TableColumn.vue";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import AreaChart from "./AreaChart";

export default {
  components: { TableColumn, BarChart, LineChart, AreaChart },
  props: ["report"],
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  methods: {
    returnCurrentColumnRange(col) {
      let totalPerCol = 20;
      let startSlice = 0;

      if (col > 1) {
        startSlice = totalPerCol * (col - 1);
        totalPerCol *= col;
      }

      return this.returnOrderJournalWithIndex.slice(startSlice, totalPerCol);
    },
    isNegative(value) {
      if (typeof value == "number") {
        if (value < 0) return true;
      } else if (+value < 0) return true;
      return false;
    },
    formattedValue(value) {
      if (value == 0 || value == "-") {
        return 0;
      } else {
        return this.$options.filters.moneyFormat(+value);
      }
    },
  },
  computed: {
    formattedGameDateTime() {
      return moment(this.report.gameSession.start_time).format(
        "DD MMM YYYY, h:mm a"
      );
    },
    countTotalOrderJournal() {
      return Math.ceil(this.report.dailyOrders.length / 20);
    },
    returnOrderJournalWithIndex() {
      return this.report.dailyOrders.map(function(data) {
        return {
          day: data.day,
          order: data.type,
          quantity: data.quantity,
          price: data.price,
        };
      });
    },
    userProfile() {
      return this.$store.getters.getUserProfile;
    },
    userProfileImage() {
      return `${this.userProfile.avatar_arr[0]}?t=` + new Date().getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  // background: #eeecec;
  padding: 10px;
  height: 100%;

  .red {
    color: rgb(245, 56, 42);
  }

  .green {
    color: rgb(47, 168, 64);
  }

  .content-header {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;

    img {
      width: 150px;
      height: auto;
    }

    h1 {
      font-size: 1.5rem;
      margin-right: 10px;
    }
  }

  .general-information {
    margin-top: 10px;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    p {
      line-height: 1.3;
    }
  }

  .performance-info {
    margin-top: 10px;
    padding: 10px;
    background: white;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    h3 {
      color: rgb(0, 0, 0);
    }

    table {
      width: 100%;
      margin-top: 10px;
      border-collapse: collapse;
      border-spacing: 0px;
    }

    td {
      border: 1px solid black;
      padding: 0 10px;
      height: 60px;
      font-size: 14px;
    }

    .summary-trades {
      h2 {
        margin-top: 5px;
      }
    }
  }

  .order-journal {
    margin-top: 10px;
    padding: 10px;
    background: white;

    .grid {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(auto-fit, 245px);
      grid-gap: 10px;
    }

    table {
      table-layout: fixed;
      height: min-content;
      border-collapse: collapse;
      border-spacing: 0px;
    }

    td {
      border: 1px solid black;
    }

    thead td {
      background: #c00000;
      color: white;
    }

    :is(tbody, thead) td {
      text-align: center;
      height: 20px;
    }

    .isBuy {
      background: #c6efce;
      color: #2c812e;
    }

    .isSell {
      background: #ffc7ce;
      color: #b32e35;
    }
  }

  .page-footer {
    padding-top: 20px;
    padding-bottom: 5px;

    font-size: 12px;
    color: $grayDark;

    h3 {
      color: $red;
      font-size: initial;

      span {
        color: initial;
        font-size: 11px;
      }
    }

    p {
      line-height: 1.5;
    }

    .contacts {
      display: flex;

      & > * {
        margin-right: 5px;
      }
    }
  }

  .graph-section {
    margin-top: 10px;
    padding: 10px;
    background: white;
  }
}

.line-chart {
  position: relative;

  .additional-legend {
    position: absolute;
    bottom: 17px;
    left: 228px;
    z-index: 99999999;
    display: flex;

    .circle {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #4caf50;
      margin-right: 3px;
    }

    p {
      font-size: 12px;
      font-family: Helvetica, Arial, sans-serif;
      font-weight: 400;
      color: rgb(55, 61, 63);
    }
  }
}
</style>
