<template>
  <apexchart
    width="700"
    height="220"
    type="area"
    :options="chartOptions"
    :series="series"
    v-if="chartData != null"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["chartData", "title", "attribute"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Equity Margin",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 225,
          toolbar: {
            show: false,
            tools: {
              download: true,
            },
          },
          animations: {
            enabled: false,

            animateGradually: {
              enabled: false,
            },

            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        title: {
          text: "",
        },
        colors: ["#4caf50"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          type: "numeric",
          categories: [],
          labels: {
            rotate: -90,
          },
          tickAmount: 10,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    returnSpecificData(report, dataType, parseData) {
      if (parseData) {
        return report.map((data) => parseFloat(data[dataType]));
      }
      return report.map((data) => data[dataType]);
    },
    checkDataLength(length) {
      if (length < 30) return length;
      else if (length >= 40) return 20;
    },
  },
  watch: {
    chartData: {
      handler: function(data) {
        if (data != null || data != undefined) {
          this.chartOptions.title.text = this.title;

          this.series = [
            {
              data: this.returnSpecificData(data, this.attribute, true),
            },
          ];

          const dataLength = this.returnSpecificData(data, this.attribute, true)
            .length;

          this.chartOptions.xaxis = {
            categories: [...Array(data.length).keys()].map((i) => i + 1),
            tickAmount: this.checkDataLength(dataLength),
            labels: {
              rotate: 0,
            },
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
