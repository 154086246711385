<template>
  <apexchart
    width="700"
    height="230"
    type="bar"
    :options="chartOptions"
    :series="series"
    v-if="chartData != null"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["chartData", "title", "attribute", "ticks"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Net Profit",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 230,
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          animations: {
            enabled: false,

            animateGradually: {
              enabled: false,
            },

            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "numeric",
          categories: [],
          labels: {
            rotate: -90,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    returnSpecificData(report, dataType, parseData) {
      if (parseData) {
        return report.map((data) => parseFloat(data[dataType]));
      }
      return report.map((data) => data[dataType]);
    },
    checkDataLength(length) {
      if (length < 30) return length;
      else if (length >= 40) return 20;
    },
  },
  watch: {
    chartData: {
      handler: function(data) {
        if (data != null || data != undefined) {
          this.chartOptions.title.text = this.title;

          this.series = [
            {
              data: this.returnSpecificData(data, this.attribute, true),
            },
          ];

          const dataLength = this.returnSpecificData(data, this.attribute, true)
            .length;

          this.chartOptions.xaxis = {
            categories: [...Array(data.length).keys()].map((i) => i + 1),
            tickAmount: this.checkDataLength(dataLength),
            labels: {
              rotate: 0,
            },
          };

          const smallestValue = Array.min(
            this.returnSpecificData(data, this.attribute, true)
          );
          const biggestValue = Array.max(
            this.returnSpecificData(data, this.attribute, true)
          );

          if (this.attribute === "net_equity") {
            this.chartOptions.yaxis = {
              min: smallestValue - 10,
              max: biggestValue,
              tickAmount: this.ticks ? this.ticks : 10,
            };
          }

          this.chartOptions.plotOptions.bar.colors = {
            ranges: [
              {
                from: 0,
                to: biggestValue,
                color: "#4caf50",
              },
              {
                from: smallestValue,
                to: 0,
                color: "#EA3546",
              },
            ],
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
