<template>
  <apexchart
    width="700"
    height="230"
    type="line"
    :options="chartOptions"
    :series="series"
    v-if="chartData != null"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["chartData", "title"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Lost",
          type: "column",
          data: [],
        },
        {
          name: "Accumulated",
          type: "line",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "line",
          stacked: false,
          height: 230,
          toolbar: {
            show: false,
            tools: {
              download: true,
            },
          },
          animations: {
            enabled: false,

            animateGradually: {
              enabled: false,
            },

            dynamicAnimation: {
              enabled: false,
            },
          },
        },
        colors: ["#EA3546", "#4472c4"],
        title: {
          text: "",
        },
        stroke: {
          width: [0, 2, 5],
          colors: ["#4472c4"],
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "numeric",
          categories: [],
          labels: {
            rotate: -90,
          },
        },
        xaxis: {
          tickAmount: 10,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    returnSpecificData(report, dataType, parseData) {
      if (parseData) {
        return report.map((data) => parseFloat(data[dataType]));
      }
      return report.map((data) => data[dataType]);
    },
    checkDataLength(length) {
      if (length < 30) return length;
      else if (length >= 40) return 20;
    },
  },
  watch: {
    chartData: {
      handler: function(data) {
        if (data != null || data != undefined) {
          this.chartOptions.title.text = this.title;

          this.series = [
            {
              type: "column",
              data: this.returnSpecificData(data, "profit_daily", true),
              name: "Loss",
            },
            {
              type: "line",
              data: this.returnSpecificData(data, "profit", true),
              name: "Accumulated",
            },
          ];

          const dataLength = this.returnSpecificData(data, this.attribute, true)
            .length;

          this.chartOptions.xaxis = {
            categories: [...Array(data.length).keys()].map((i) => i + 1),
            tickAmount: this.checkDataLength(dataLength),
            labels: {
              rotate: 0,
            },
          };

          this.chartOptions.plotOptions.bar.colors = {
            ranges: [
              {
                from: 0,
                to: Array.max(
                  this.returnSpecificData(data, "profit_daily", true)
                ),
                color: "#4caf50",
              },
              {
                from: Array.min(
                  this.returnSpecificData(data, "profit_daily", true)
                ),
                to: 0,
                color: "#EA3546",
              },
            ],
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
